// import { ISocialsData } from "../../../modules/mfrm-footer/mfrm-footer.props.autogenerated";

export interface ISocials {
    ariaLabel: String
    label: String
    type: String
    url : String
    openInNewTab: Boolean
    image: {
        url: String
        altText?: String
    }
}

//d365
export const d365ToSocials = (socials: any) => {
    if (socials) {
        return socials.map((socials: any) => {
            return {
                ariaLabel: socials.altText,
                label: socials.title,
                type: null,
                url : socials.socialUrl.linkUrl.destinationUrl,
                openInNewTab: socials.socialUrl.openInNewTab,
                image: {
                    url: socials.src,
                    altText: socials.altText
                    }
            };
        });
    }
    return null;
};

//bedhub ISocials  ISocialsData
export const bedhubToSocials = (socials: any) => {
     if (socials !== undefined || null) {
        return socials.map((socials: any) => {
            return {
                ariaLabel: socials?.ariaLabel,
                label: socials?.label,
                type: socials.type,
                url : socials.url,
                openInNewTab: socials.openInNewTab,
                image: {
                    url: socials.image.url
                }
            };
        });
    }
    return null;
};