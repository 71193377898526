import { IAccessibilityReaderData } from "../../../modules/mfrm-footer/mfrm-footer.props.autogenerated";

export interface UIAccessibilityD365 {
    ariaLabel: String
    label: String
    url: String
    openInNewTab: Boolean
    image: {
        url: String
        altText: String
    }
    type: String | null
  }

//D365
export const d365ToAccessibilityReader = (accessibilityReader: IAccessibilityReaderData | undefined) => {
    if (accessibilityReader) {
            return {
                ariaLabel: accessibilityReader.accessibilityReaderLink.ariaLabel,
                label:accessibilityReader.accessibilityReaderLink.linkText,
                url: accessibilityReader.accessibilityReaderLink.linkUrl.destinationUrl,
                openInNewTab: accessibilityReader.accessibilityReaderLink.openInNewTab,
                image:  {
                    url: accessibilityReader.accessibilityReaderImage.src,
                    altText: accessibilityReader.accessibilityReaderImage.altText
                  }
            };
    }
    return null;
};

//bedhub
export const BedhubToAccessibilityReader = (accessibilityReader: UIAccessibilityD365 | undefined) => {
    if (accessibilityReader !== undefined || null) {
            return {
                ariaLabel: accessibilityReader?.ariaLabel,
                image:  {
                   url: accessibilityReader?.image.url
                },
                label:accessibilityReader?.label,
                openInNewTab: accessibilityReader?.openInNewTab,
                type: accessibilityReader?.type,
                url: accessibilityReader?.url
            };
    }
    return null;
};