import Accordion from 'mfcl/Accordion';
import React from 'react';


export const Categories = (categories: any) => {

    const categoryElement = categories.categories.map((item:any) => {
        return (
          <div className='mfrm-footer_categories_container' key={item.title}>
            <h2 className='mfrm-footer_categories_header' >{item.title}</h2>
            <ul className='mfrm-footer_categories_list-container'>
              {item.items.map((item:any, i:number) => {
                const labels = item.label;
                const url = item.url;
                const ariaLabel = item.ariaLabel;
                return labels.map((label:string, i: number) => (
                  <li key={label}>
                    <a
                      href={url[i]}
                      aria-label={ariaLabel[i]}
                      data-testid={ariaLabel[i]}
                      title={ariaLabel[i]}
                      target={item.openInNewTab[i] ? '_blank' : undefined}
                      className='mfrm-footer_categories_item'
                    >
                    {label}
                    </a>
                  </li>
                )
              );})}
            </ul>
          </div>
        );
      });

      return (
        <div className='categories-wrapper'>
          <div className='mfrm-footer_categories_desktop-view'>
            {categoryElement}
          </div>

          <div className='mfrm-footer_categories_mobile-view'>
            {categories.categories.map((item:any) => {
              return (
                <div key={item.label}>
                  <Accordion
                    key={item.label}
                    className='mfrm-footer_categories_mfcl-accordion'
                    items={[
                      {
                        title: item.title,
                        id: item.title,
                        content:
                        <ul className='categories-list'>
                          {item.items.map((item:any, i:number) => {
                            const labels = item.label;
                            const url = item.url;
                            const ariaLabel = item.ariaLabel;
                            return labels.map((label:string, i: number) => (
                              <li key={label}>
                                <a
                                href={url[i]}
                                aria-label={ariaLabel[i]}
                                target={item.openInNewTab[i] ? '_blank' : undefined}
                                >
                                {label}
                                </a>
                              </li>
                            )
                          );})}
                        </ul>
                      }
                    ]}
                  />
                </div>
              );
            })}
          </div>
      </div>
      );
};