import React from 'react';
import { IMfrmFooterViewProps } from '../../modules/mfrm-footer/mfrm-footer';
import { bedhubToSocials, d365ToSocials } from '../adaptor/footer/socials';
import { BedhubToAccessibilityReader, d365ToAccessibilityReader } from '../adaptor/footer/accessibilityReader';
import { bedhubToCategories, d365ToCategories } from '../adaptor/footer/categories';
import { bedhubToDisclosures, d365ToDisclosures } from '../adaptor/footer/disclosures';
import { Categories } from './footer-components/Categories';
import { Socials } from './footer-components/Socials';
import { AccessibilityReader } from './footer-components/AccessibilityReader';
import { Disclosures } from './footer-components/Disclosures';

export const MFRMFooter = (props: IMfrmFooterViewProps) => {
  //D365 adaptor data
  const { categories, socials, accessibilityReader, disclosure } = props.config;
  const D365CategoriesData = d365ToCategories(categories);
  const D365SocialsData = d365ToSocials(socials);
  const D365AccessibilityData = d365ToAccessibilityReader(accessibilityReader);
  const d365DisclosuresData = d365ToDisclosures(disclosure);

  //bedhub adaptor data
  let bedhubDataSource = props.config.bedhubDataSource;

  const {
    data: {
      bedhubFooter: { result: bedhubFooter }
    }
  } = props;

  if (bedhubFooter?.data?.ui === undefined || bedhubFooter === undefined ) {
    bedhubDataSource = false;
  }

  //bedhub disclosures and show more logic
  const bedhubDisclosures =  bedhubFooter?.data?.ui?.disclosure;
  const bedhubDisclosureData = bedhubToDisclosures(bedhubDisclosures);
  const disclosuresData = bedhubDataSource ? bedhubDisclosureData : d365DisclosuresData;
  const disclosuresContent = disclosuresData?.sections !== undefined ? disclosuresData?.sections?.map((section: any, index: number) => (
    <Disclosures disclosures={section} maxCount={disclosuresData?.maximum} key={index} />
  )) : null;

  //bedhub footer data
  const categoriesBedhub = bedhubFooter?.data?.ui?.footer.categories;
  const socialsBedhub = bedhubFooter?.data?.ui?.footer.socials;
  const accessibilityReaderBedhub = bedhubFooter?.data?.ui?.footer.accessibilityReader;
  const bedhubAccessibility = BedhubToAccessibilityReader(accessibilityReaderBedhub);
  const bedhubCategories = bedhubToCategories(categoriesBedhub);
  const bedhubSocials = bedhubToSocials(socialsBedhub);

  //condition for which data to use
  const accessibilityData = bedhubDataSource ? bedhubAccessibility : D365AccessibilityData;
  const categoriesData = bedhubDataSource ? bedhubCategories : D365CategoriesData;
  const socialsData = bedhubDataSource ? bedhubSocials : D365SocialsData;


  return (
    <div className='mfrm-footer_container'>
      <div className='mfrm-footer_categories-socials-wrapper'>
        <Categories categories={categoriesData} />
        <Socials socials={socialsData} />
      </div>
      <AccessibilityReader accessibilityReader={accessibilityData} />
      {disclosuresContent}
    </div>
  );
};

export default MFRMFooter;