import React from 'react';
import { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

export const Disclosures = (props: any) => {
  const { html, truncate } = props.disclosures;
  const { maxCount } = props;

  const filteredHtml = html.replace('&amp;nbsp;', '\u00A0');

  const truncateText = truncate || false;
  const [displayTruncateParagraph, setDisplayTruncateParagraph ] = useState(truncateText);
  const onClick = () => setDisplayTruncateParagraph(!displayTruncateParagraph);

  const truncateTextFunction = (inputText: string, letterLimit: number) => {
    let startIndex = 0;
    for(let i = 1; i < inputText.length; i++){
        if(inputText[i] === '>'){
            startIndex = i+1;
            break;
        }
    }
    const stack = [];
    let letterCount = 0;
    for(let i = startIndex; i < inputText.length; i++){
        if(letterCount === letterLimit){
            return inputText.slice(startIndex, i+1);
        }
        else if(inputText[i] === '<'){
            stack.push(inputText[i]);
        }
        else if(inputText[i] === '>'){
            stack.pop();
        }
        else if(stack.length === 0){
            letterCount++;
        }
    }
    return '';
  };

  const htmlToDisplay = html && truncateTextFunction(ReactHtmlParser(filteredHtml)[0]?.props?.children[0], maxCount);

  return(
    <div className='mfrm-footer_disclosures_content'>
        {(displayTruncateParagraph) ?
            <div>
                <p data-testid='Disclosures'>
                    {ReactHtmlParser(htmlToDisplay)}
                    <span className='mfrm-footer_show-more-text-connect'>...<button onClick={onClick} className={'mfrm-footer_show-more-text'}>Show More</button></span>
                </p>
            </div>
            : <p>{ReactHtmlParser(filteredHtml)}</p>
        }
    </div>
    );
};
