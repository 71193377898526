import React from 'react';

interface ISocialProps {
  socials: any;
}

export const Socials:React.FC<ISocialProps> = ({socials}) => {

  const socialsElement = socials.map((social: any) => {
    return (
      <div
        key={socials.ariaLabel}
        className='mfrm-footer_socials_icon-container'
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          // @ts-ignore
          if ('utag' in window) {
              // @ts-ignore
              // tslint:disable-next-line: object-literal-key-quotes max-line-length
              utag.link({
                  event: 'outbound-social-link-click',
                  event_category: 'outbound links',
                  event_action: 'social link',
                  event_label: `to: ${social.url}`,
                  event_noninteraction: 'false'
              });
          }
        }}
      >
        <a
          href={social.url}
          className='mfrm-footer_socials_icon-link'
          aria-label={social.ariaLabel}
          target={social.openInNewTab ? '_blank' : undefined}
          data-testid={social.ariaLabel}
          title={social.ariaLabel}
        >
          <img title={social.ariaLabel} src={social.image.url} alt={social.ariaLabel} className={`mfrm-footer_socials_icon_${social.ariaLabel.toLowerCase()}`}/>
        </a>
      </div>
    );
  });

  if (socials) {
    return (
      <div className='mfrm-footer_socials_icons-wrapper'>{socialsElement}</div>
    );
  } return null;
};
