import { IDisclosureData } from "../../../modules/mfrm-footer/mfrm-footer.props.autogenerated";

export interface IDisclosures {
        maximum?: Number | null;
        more?: {
            ariaLabel?: String,
            label?: String
        }
        sections: ISectionsData[] | undefined;
}

export interface ISectionsData {
    html: any;
    truncate?: boolean;
}

export const d365ToDisclosures = (disclosures: IDisclosureData | undefined): IDisclosures | null => {
    if (disclosures) {
        return {
            maximum: disclosures?.maximum,
            sections: disclosures.sections?.map(disclosure => {
                return {
                    html: disclosure.html,
                    truncate: disclosure.truncated
                };
            })
        };
    } return null;
};

//bedhub adaptor
export const bedhubToDisclosures = (disclosures: any) => {
    if (disclosures !== undefined || null) {
        return {
            maximum: disclosures?.maximum,
            sections: disclosures?.sections?.map((disclosure:any) => {
                return {
                    html: disclosure.html,
                    truncate: disclosure.truncate
                };
            })
        };
    } return null;
};
