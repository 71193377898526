import { ICategoriesData, ICategoryItemsData } from "../../../modules/mfrm-footer/mfrm-footer.props.autogenerated";

export interface ICategories {
    label: String;
    items: ICategoryItems[]
    title: String
}

export interface ICategoryItems {
    ariaLabel: String
    url: String
    label: String
    openInNewTab: Boolean
    type: String | null
}

// D365 data
export const d365ToCategories = (categories: ICategoriesData | undefined) => {
    const d365Categories = [categories?.aboutCategory!,  categories?.helpCategory!,  categories?.legalCategory!];
    if (categories) {
        return d365Categories.map((category) => {
            return {
                title: category.label,
                items: [{
                    ariaLabel: category.categoryItems.map((item: ICategoryItemsData) => item.ariaLabel),
                    url:category.categoryItems.map((item: ICategoryItemsData) => item.linkUrl.destinationUrl),
                    label: category.categoryItems.map((item: ICategoryItemsData) => item.linkText),
                    openInNewTab: category.categoryItems.map((item:ICategoryItemsData) => item?.openInNewTab)
                }]
            };
        });
    } return null;
};

export const bedhubToCategories = (categories: ICategories[] | undefined) => {
    if (categories !== undefined || null) {
        return categories?.map((category) => {
            return {
                title: category.label,
                items: [{
                    ariaLabel: category.items.map((item: ICategoryItems) => item.ariaLabel),
                    label: category.items.map((item: ICategoryItems) => item.label),
                    openInNewTab : category.items.map((item: ICategoryItems) => item.openInNewTab),
                    url:category.items.map((item: ICategoryItems) => item.url),
                    type: category.items.map((item: ICategoryItems) => item.type)
                }]
            };
        });
    } return null;
};