import React from 'react';

export const AccessibilityReader = (accessibilityReader:any) => {


  const accessibility = accessibilityReader.accessibilityReader;

  if (accessibility){
    return (
      <div key={accessibility.ariaLabel} className='mfrm-footer_accessibility_container'>
        <a
          href={accessibility.url}
          className='mfrm-footer_accessibility_link'
          target={accessibility.openInNewTab ? '_blank' : ''}
          aria-label={accessibility.ariaLabel}
          data-testid={accessibility.ariaLabel}
          title={accessibility.ariaLabel}
        >
          <img src={accessibility.image.url} alt={accessibility.ariaLabel} className='mfrm-footer_accessibility_image'/>
        </a>
      </div>
    );
  } return null;
};